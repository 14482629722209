import React from "react"
import tw from "twin.macro"
import { HighlightBlock } from "./highlight-block"

interface Highlights {
  title: string
  body: string
  icon: string
}

interface HighlightBlocksProps {
  highlights: Highlights[]
}

const Container = tw.div`
  flex
  flex-wrap
  md:flex-row
  justify-center
  mt-10
  items-start
`

export const HighlightBlocks: React.FC<HighlightBlocksProps> = ({
  highlights,
}) => {
  return (
    <Container>
      {highlights.map(({ title, body, icon, alt }) => (
        <HighlightBlock
          key={title}
          title={title}
          body={body}
          icon={icon}
          alt={alt}
        />
      ))}
    </Container>
  )
}
