import React from "react"
import tw, { styled } from "twin.macro"
import { H4, H2, P, Button } from "../components/page-elements"
import Image from "../components/image"
import { SectionTitle } from "../components/section-title"
import { HighlightBlocks } from "../components/highlight-blocks"
import CustomerService from "../images/customer-service.svg"
import NoLimitDesign from "../images/no-limit-design.svg"
import NoPrewrittenContent from "../images/no-prewritten-content.svg"
import { ContactForm } from "../components/contact-form"
import { Banner } from "../components/banner"

const Container = tw.div`
    max-w-screen-lg
    flex
    flex-col
    justify-center
    m-auto
`

const ContentContainer = tw.div`
    flex
    flex-wrap
    px-page-gutter
    lg:mb-20
`

const ContentBody = tw.div`
    w-full
    lg:flex-1
    lg:w-1/2
    lg:ml-4
`

const StyledImage = tw(Image)`
    w-full
    rounded-lg
    mb-4
    lg:w-1/2
    lg:mb-0
    lg:flex-1
    lg:mr-4
`

const Mono = styled(H4)`
  ${tw`m-0 text-left text-header`}
`

const ArticleHeader = styled(H2)`
  ${tw`mt-0 text-header`}
`

const ContentText = styled(P)`
  ${tw`text-body tracking-wide`}
`

const Highlights = tw.div`
    my-8
    lg:my-20
    flex
    flex-col
    flex-wrap
    justify-center
    px-page-gutter
`

const highlights = [
  {
    title: "Uniquely Tailored Content",
    body:
      "We get to know your company and create personalized and engaging content to better reach your customers.",
    icon: NoPrewrittenContent,
  },
  {
    title: "Exceptional Customer Service",
    body:
      "We don’t automate anything, especially our customer service. When you call, you’ll speak to a lead developer eager to help you with your site.",
    icon: CustomerService,
  },
  {
    title: "Unlimited Design",
    body:
      "Your website is the brick and mortar of your business, the first thing curious clients see.  It should be as unique as you are and we make sure everything is customizable.",
    icon: NoLimitDesign,
  },
]

const PAGE_CONSTANTS = {
  title: "About",
  header: "About Us",
  description:
    "We're a small business providing websites for other small businesses at an affordable price of $0 down, $150 a month.",
}

const AboutPage = () => {
  return (
    <Container>
      <Banner
        title={PAGE_CONSTANTS.title}
        header={PAGE_CONSTANTS.header}
        desctiption={PAGE_CONSTANTS.description}
      />
      <ContentContainer>
        <StyledImage
          src="matt-and-irwin.jpg"
          alt="Irwin, the Australian cattle dog in Matt's backpack."
        />
        <ContentBody>
          <Mono>Out of Mind Web Design</Mono>
          <ArticleHeader>Your Website, Out of Mind</ArticleHeader>

          <ContentText>
            My name is Matthew Moran, I am the owner and lead developer of Out
            Of Mind Design based in Chicago, IL. I got into programming right
            before I got married to my wife five years ago. I was working at a
            coffee shop and felt that I needed to find a career that could
            provide for my family. I emptied my bank account to buy a computer
            and taught myself how to code. Luckily it's worked out!
          </ContentText>
          <ContentText>
            Over the last five years I have worked for a variety of different
            companies that specialize in digital marketing and advertisment.
            I've learned a ton about the industry as a whole and am now
            venturing out with all that I have learned to help small businesses.
            A lot of small business don't have the funds to spend thousands of
            dollars upfront on such a high risk invesment as a new website. It's
            not easy to know what you're going to get and therefore easy to get
            taken advantage of or to recieve a terrible looking product. After
            talking with other professionals in the industry, I'm convinced that
            the $0 down and $150 a month model is ideal for small businesses.
            It's affordable and manageable. We build your website and handle
            technical problems that you don't want to deal with. You're website,
            out of mind.
          </ContentText>
        </ContentBody>
      </ContentContainer>
      <Highlights>
        <SectionTitle text="High Quality Websites"></SectionTitle>
        <P className="text-body text-center lg:w-2/3  m-auto">
          I am not looking for growth at all costs; I want each site I develop
          for each unique client to be tailored perfectly to their needs and
          bring them more revenue. We work closely with each small business
          owner to ensure 100% satisfaction and superior search engine
          optimization.
        </P>
        <HighlightBlocks highlights={highlights} />
        <div className="flex justify-center">
          <Button to="/services">Our Services</Button>
        </div>
      </Highlights>
      <div className="px-page-gutter lg:-mb-64 z-10">
        <ContactForm />
      </div>
    </Container>
  )
}

export default AboutPage
