import React from "react"
import tw from "twin.macro"
import { P, H3 } from "../components/page-elements"

interface HighlightBlockProps {
  title: string
  icon: string
  body: string
  alt: string
}

const Container = tw.div`
    flex
    flex-col
    justify-center
    text-center
    my-6
    w-full
    md:w-1/3
    md:px-8
`

const Icon = tw.img`
  w-24
  h-24
  block
  m-auto
`

export const HighlightBlock: React.FC<HighlightBlockProps> = ({
  title,
  icon,
  body,
  alt,
}) => {
  return (
    <Container>
      <Icon src={icon} alt={alt} />
      <H3 className="text-header">{title}</H3>
      <P className="text-body">{body}</P>
    </Container>
  )
}
